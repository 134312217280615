import React from "react";
import { useTranslation } from "react-i18next";
import { Drawer, List, ListItem, ListItemText, Box, Typography, AppBar, Toolbar, CssBaseline, Button, Link } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../index";

const drawerWidth = 240;

const Dashboard = () => {
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Toolbar>
            <Box display="flex" flexGrow={1}>
              <Typography variant="h6" noWrap component="div">
                {t("Dashboard")}
              </Typography>
            </Box>

            {/* Faire la fonction de déconnexion */}

            <Link href="#" color="inherit">
              {t("logout")}
            </Link>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
          }}
        >
          <Toolbar />
          <Box sx={{ overflow: "auto" }}>
            <List>
              {["Home", "Profil", "Settings"].map((text, index) => (
                <ListItem ListItemButton key={text}>
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}>
          <Toolbar />
          <Typography paragraph>{t("Welcome in your dashboard")}</Typography>
          {/* Ajoutez plus de contenu pour le dashboard ici */}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Dashboard;
