import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Button, TextField, CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { login } from "../../features/authSlice";

function LoginForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, isAuthenticated } = useSelector((state) => state.auth);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard");
    }
  }, [isAuthenticated, navigate]);

  const validate = () => {
    let isValid = true;
    if (!email) {
      setEmailError(t("Email is required"));
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError(t("Email is invalid"));
      isValid = false;
    } else {
      setEmailError("");
    }

    if (!password) {
      setPasswordError(t("Password is required"));
      isValid = false;
    } else {
      setPasswordError("");
    }

    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validate()) {
      dispatch(login({ email, password }));
    }
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <form onSubmit={handleSubmit}>
        <TextField
          label={t("Email")}
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={!!emailError}
          helperText={emailError}
          fullWidth
          margin="normal"
        />
        <TextField
          label={t("Password")}
          type="password"
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={!!passwordError}
          helperText={passwordError}
          fullWidth
          margin="normal"
        />
        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
          <Button type="submit" color="primary" variant="contained" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : t("login")}
          </Button>
        </Box>
        {error && (
          <Typography color="error" align="center" mt={2}>
            {error.message || t("Login failed")}
          </Typography>
        )}
      </form>
    </Box>
  );
}

export default LoginForm;
