import React from "react";
import { useTranslation } from "react-i18next";
import { AppBar, Box, Button, Container, Link, Toolbar, Typography } from "@mui/material";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import LoginForm from "./components/Login/LoginForm";
import Dashboard from "./components/Dashboard/Dashboard";
import { useSelector } from "react-redux";

function App() {
  const { t } = useTranslation();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  return (
    <Router>
      <AppBar position="static">
        <Toolbar>
          <Box display="flex" flexGrow={1}>
            <Button color="inherit">{t("tools")}</Button>
            <Button color="inherit">{t("pricing")}</Button>
            <Button color="inherit">{t("api")}</Button>
          </Box>
          <Button color="inherit">{t("login")}</Button>
          <Link href="#" color="inherit">
            {t("signup")}
          </Link>
        </Toolbar>
      </AppBar>

      <Container>
        <Routes>
          <Route path="/" element={<LoginForm />} />
          <Route path="/dashboard" element={isAuthenticated ? <Dashboard /> : <Navigate to="/" />} />
        </Routes>
      </Container>
    </Router>
  );
}

export default App;
